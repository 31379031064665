/** @jsx jsx */
import HomePageLayout from '../../components/HomePageLayout';
import { graphql } from 'gatsby';
import { jsx } from 'theme-ui';
import HeroSectionContainer from '@fify.at/gatsby-theme-base/src/components/HeroSection/HeroSectionContainer';
import Section from '@fify.at/gatsby-theme-base/src/components/Section/Section';
import Subheading from '@fify.at/gatsby-theme-base/src/components/Subheading/Subheading';
import Heading from '@fify.at/gatsby-theme-base/src/components/Heading/Heading';
import Divider from '@fify.at/gatsby-theme-base/src/components/Divider/Divider';

export default function Unternehmen({ location, data }) {
  return (
    <HomePageLayout
      location={location}
      lang="de"
      title="Ein Unternehmen stellt sich vor"
      description="Wir bieten privaten Kunden eine Plattform für die einfache und schnelle Abwicklung des Ankaufs ihrer Immobilie – ganz ohne Risiko, bei voller Transparenz in jedem Schritt des Verkaufsprozesses. Der Zustand Ihrer Immobilie ist dafür für uns zweitrangig."
      url={location.href}
      image={data.hero.childImageSharp.fluid}
      keywords={['Unternehmen']}
      header={{ attachment: 'default', appearance: 'default', fluid: true }}
    >
      <HeroSectionContainer
        fluid={false}
        orientation="southEast"
        variant="alpha"
        title="WirkaufendeinHaus.at"
        subtitle="Ein Unternehmen stellt sich vor"
        image={data.hero}
      />
      <Section container="small">
        <div sx={{ marginBottom: 7 }}>
          <Heading>
            WirKaufenDeinHaus.at – der neue, einfache Weg Ihr Haus zu verkaufen.
          </Heading>
        </div>
        <p>
          WirKaufenDeinHaus.at wurde von mehreren renommierten, österreichischen
          Unternehmen als neue, bahnbrechende Idee 2020 gegründet und basiert
          auf einem starken Immobilien-Background.
        </p>
        <p>
          Besonders stolz sind wir auf unsere enge und gelebte Partnerschaft mit
          Donau Immobilien Mag. Fürstl GmbH & CO KG. Das Unternehmen wurde laut
          Immobilien-Magazin 2019 zu Österreichs erfolgreichsten Häusermakler
          gekürt und bringt die entsprechende Expertise für den Verkauf von
          Eigenheimen mit. Des Weiteren vermittelt Geschäftsführer Mag. Fürstl
          seine jahreslanges Wissen als Vortragender für den Studiengang
          „Immobilienveranlagungen“ an der Donau Universität Krems.
        </p>
        <h3>Unsere Philosophie & Werte</h3>
        <p>
          <strong>
            WirkaufendeinHaus.at verbindet Menschen und Immobilien.
          </strong>
        </p>
        <p>
          Wir bieten privaten Kunden eine Plattform für die einfache und
          schnelle Abwicklung für den Ver- und Ankauf ihrer Immobilie – ganz
          ohne Risiko, bei voller Transparenz in jedem Schritt des
          Verkaufsprozesses.
        </p>
        <p>
          Teil unserer Philosophie ist es, stets lösungsorientiert zu denken und
          zu handeln. Dabei sind unserem Unternehmen Handschlagqualität und
          gelebte Nachhaltigkeit besonders wichtig.
        </p>
        <p>
          Wir arbeiten diskret, professionell und können aufgrund unserer
          Flexibilität anfallende Projekte in kürzester Zeit abwickeln. Durch
          unsere langjährige Erfahrung gehen wir individuell auf die
          persönlichen Bedürfnisse der Interessenten ein.
        </p>
        <p>
          Des Weiteren bieten wir attraktive Investmentstrategien für Anleger
          und einzigartige Partnerprogramme.
        </p>
        <p>
          WirkaufendeinHaus.at ist 2020 in Ostösterreich tätig. Weitere
          Bundesländer sowie der Sprung nach Deutschland sind zeitnah geplant.
        </p>
      </Section>
    </HomePageLayout>
  );
}

export const pageQuery = graphql`
  {
    hero: file(name: { eq: "hero-home" }) {
      childImageSharp {
        fluid(maxWidth: 2340, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
